.card {
  border: 1px solid var(--black-light);
  padding: 16px;
  height: 136px;
  background-color: var(--black-normal);
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.title {
  font-size: 20px;
  font-weight: 800;
}

.title a {
  color: white;
}
