.capacity > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 800;
  color: var(--gray-normal);
}

.capacityValue {
  display: flex;
  align-items: center;
  color: white;
  font-size: 26px;
}

.capacityValueTonnes {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  font-size: 26px;
}

.capacityValueTonnes:hover {
  color: var(--accent-normal);
}

.noHover {
  pointer-events: none;
}
