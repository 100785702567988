.recipe {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.recipe strong {
  font-weight: 800;
}

.recipeTitle {
  padding: 16px;
  background-color: var(--black-normal);
}

.duration > strong {
  font-weight: 800;
}

.duration :global .anticon {
  padding: 0 16px;
}

.recipeTitle :global .ant-badge-status-dot {
  width: 12px;
  height: 12px;
}

.ratios {
  padding: 0 16px;
  border-left: 1px solid #535353;
}

.oreLeft {
  padding: 0 16px;
  border-left: 1px solid #535353;
}

.author {
  text-align: right;
}

.toolBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.elementsAndTons {
  padding-top: 8px;
}

.element {
  text-align: right;
  padding: 0 32px;
  border-bottom: 1px solid #535353;
  font-weight: 800;
}

.elementLast {
  text-align: right;
  padding: 0 16px 0 32px;
  border-bottom: 1px solid #535353;
  font-weight: 800;
}

.grade {
  text-align: right;
  padding: 0 32px;
}

.gradeLast {
  text-align: right;
  padding: 0 16px 0 32px;
}

.menu {
  width: 16px;
}

.menu :global .anticon:hover {
  color: var(--accent-normal);
  cursor: pointer;
}

.menuContent {
  white-space: nowrap;
  color: black;
}

.menuContent > span {
  display: block;
  cursor: pointer;
}

.menuContent :global .anticon {
  padding-right: 8px;
}

.menuContent > span:hover {
  color: var(--primary-dark);
}

.strong {
  font-weight: 800;
}
