.percentageSelector {
  display: flex;
}

.percentageSelector :global .ant-radio-group {
  font-weight: 800;
}

.percentageSelector :global .ant-radio-group-small .ant-radio-button-wrapper {
  padding: 0 12px;
}

.percentageSelector :global .ant-radio-button-wrapper {
  font-size: 12px;
}

.percentageSelector
  :global
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--black-dark);
  background: var(--accent-normal);
  border-color: var(--accent-normal);
}

.percentageSelector :global .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--accent-normal);
}

.percentageSelector
  :global
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: var(--accent-normal);
}

.percentageSelector
  :global
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--black-dark);
  background: var(--accent-hover);
  background-color: var(--accent-hover);
  border-color: var(--accent-hover);
}

.percentageSelector :global .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--accent-normal);
}

.percentageSelector :global .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: var(--accent-hover);
}

.percentageSelector :global .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: var(--black-dark);
  background: var(--accent-normal);
  border-color: var(--accent-normal);
}
