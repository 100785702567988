.element {
  display: flex;
  flex-direction: column;
  font-weight: 800;
}

.element > span:first-child {
  font-size: 16px;
  color: var(--accent-normal);
  line-height: 1;
}

.element > span:last-child {
  font-size: 12px;
  white-space: nowrap;
}

.grade {
  font-weight: 800;
  font-size: 34px;
  line-height: 1;
  text-align: right;
  padding-right: 16px;
}
