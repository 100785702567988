.recipePlanner {
  height: 100%;
  display: flex;
}

.stockpiles {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid var(--black-light);
}

.stockpilesHeader {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.stockpilesHeader :global .ant-btn > .anticon {
  color: var(--primary-normal);
}

.stockpilesHeader :global .ant-badge-status-dot {
  width: 12px;
  height: 12px;
}

.slices {
  flex: 1;
}

.summary {
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 320px;
}

.title {
  font-size: 20px;
  font-weight: 800;
}

.tableTitleBar {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.stockpileTitle {
  flex: 0 0 auto;
  font-weight: 800;
  font-size: 20px;
}

.recipeInfo {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}
