.recipePlanner {
  height: 100%;
  display: flex;
}

.stockpiles {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid var(--black-light);
}

.stockpilesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.slices {
  flex: 1;
}

.visible {
  display: block;
}

.summary {
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 320px;
}

.sliceSeletorButton {
  font-size: 16px;
  font-weight: 800;
}

.stockpilesHeader :global .ant-btn > .anticon {
  color: var(--primary-normal);
}

.title {
  font-size: 20px;
  font-weight: 800;
}

.tableTitleBar {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.stockpileTitle {
  flex: 0 0 auto;
  font-weight: 800;
  font-size: 20px;
}

.actionButtons {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
}

.actionButtons :global .ant-btn-primary {
  background: var(--primary-normal);
  border-color: var(--primary-normal);
}

.recalculating {
  font-size: 16px;
  padding-top: 32px;
}

.recipePlanner :global .ant-divider-horizontal {
  margin: 16px 0;
}
