.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 800;
  min-height: 100%;
}

.waitAminute {
  font-size: 16px;
  font-weight: 500;
}

.fetchError {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.refetch {
  cursor: pointer;
  color: var(--primary-normal);
}

.refetch:hover {
  color: var(--accent-normal);
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
}

.unauthorizedError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error :global .ant-typography.ant-typography-secondary {
  font-size: 14px;
  font-weight: 500;
}
