.summary {
  padding: 10px 16px 0 16px;
  height: 115px;
  position: relative;
  border-bottom: 8px solid var(--black-normal);
  margin-bottom: 30px;
}

.header {
  padding-bottom: 15px;
  font-weight: 800;
}

.header :global h5.ant-typography {
  font-weight: 800;
}
