@import '~antd/dist/antd.dark.less';

:root {
  --primary-light: #4db0d8;
  --primary-normal: #1aa3da;
  --primary-dark: #126789;
  --black-light: #393939;
  --black-normal: #292929;
  --black-dark: #1f1f1f;
  --accent-light: #f3d55b;
  --accent-normal: #f2c617;
  --accent-dark: #e7ba08;
  --accent-hover: #a48607;
  --notification-green: #0ac01d;
  --gray-normal: #828282;
  --error: #a61d24;
}

@font-face {
  font-family: 'Raleway';
  src: './fonts/Raleway-Italic-VariableFont_wght.ttf' format('font/ttf');
}

body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

body {
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-table-row-indent + .ant-table-row-expand-icon:hover {
  color: var(--accent-normal);
}

.ant-table-row-indent + .ant-table-row-expand-icon:active {
  color: var(--accent-light);
}

.ant-table-row-indent + .ant-table-row-expand-icon:focus {
  color: var(--accent-dark);
}

@body-background: #1f1f1f;