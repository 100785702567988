.filtersMenu :global .ant-menu-horizontal:not(.ant-menu-dark) {
  background: var(--black-dark);
}

.filtersMenu :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin: 0;
  padding: 0 30px;
}

.filtersMenu :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  font-weight: 800;
  color: var(--accent-normal);
  border-bottom-color: var(--accent-normal);
}

.filtersMenu :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: var(--accent-normal);
  border-bottom-color: var(--accent-normal);
}
