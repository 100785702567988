.titleBar {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 800;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
}

.title {
  font-size: 26px;
}

.startTime :global .ant-switch-checked {
  background-color: var(--accent-normal);
}
