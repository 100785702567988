.summary {
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 320px;
}

.summaryHeader {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summaryTitle {
  font-weight: 800;
}

.summaryItem {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 800;
  padding-bottom: 12px;
}

.element {
  color: var(--accent-normal);
}

.content {
  font-size: 12px;
  color: var(--gray-normal);
}

.values {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balance {
  font-weight: 800;
}
