.dashboard {
  min-height: 100%;
  display: flex;
}

.stockpilesContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  padding: 16px 16px 0 16px;
  border-right: 1px solid var(--black-light);
}

.stockpiles {
  min-height: 100%;
}

.recalculating {
  font-size: 16px;
  padding-top: 32px;
}

.recalculatingWarning {
  padding: 16px;
  font-size: 16px;
  font-weight: 800;
  color: red;
}

.noAccessRights {
  display: flex;
  justify-content: center;
  align-items: center;
}
