.menu {
  white-space: nowrap;
  color: black;
}

.menu .menuItem {
  cursor: pointer;
}

.menuItem :global .anticon {
  padding-right: 8px;
}

.menu .menuItem:hover {
  color: var(--primary-dark);
}
