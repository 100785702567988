.logo {
  padding-right: 2rem;
}

.user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  font-size: 16px;
  padding-left: 2rem;
}

.user .username {
  padding-right: 16px;
}

.header nav :global .ant-menu {
  min-width: 350px;
  font-size: 16px;
}

.header :global .ant-menu-horizontal:not(.ant-menu-dark) {
  background: var(--black-dark);
  border-bottom: 0;
}

.header :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  font-weight: 800;
  margin: 0;
  padding: 0 30px 0 30px;
}

.header :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: var(--accent-normal);
  border-bottom-color: var(--accent-normal);
  background: var(--black-normal);
}

.header :global .ant-menu-horizontal > .ant-menu-item-selected a {
  color: var(--accent-normal);
}

.header :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  border-bottom-color: var(--accent-normal);
}

.header :global .ant-menu-horizontal > .ant-menu-item a:hover {
  color: var(--accent-normal);
}

.recipePlannerBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 30px;
  padding: 0 16px;
  margin-right: 32px;
  border-radius: 20px;
  color: black;
  font-weight: 800;
  background: var(--accent-normal);
}

.recipePlannerBtn :global .anticon {
  display: block;
  color: black;
}

.userMenuItem {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
}

.userMenuItem:hover {
  color: var(--accent-normal);
}

.avatar {
  cursor: pointer;
}

.avatar :global .anticon-user:hover {
  color: var(--accent-normal);
}

.env {
  color: #f5222d;
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
}
