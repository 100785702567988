.recipeConfirmationModal {
}

.recipeTime {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.timeStamp {
  display: flex;
  flex-direction: column;
}

.recipeConfirmationModal :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--accent-normal);
  border-color: var(--accent-normal);
}

.recipeConfirmationModal :global .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--black-normal);
}

.recipeConfirmationModal :global .ant-btn-primary {
  background: var(--primary-normal);
  border-color: var(--primary-normal);
}

.recipeConfirmationModal :global .ant-btn-primary:focus {
  background: var(--primary-dark);
  border-color: var(--primary-dark);
}

.recipeConfirmationModal :global .ant-btn-primary:hover {
  background: var(--primary-dark);
  border-color: var(--primary-dark);
}

.custom {
  text-transform: uppercase;
  color: var(--accent-normal);
  font-size: 12px;
  font-weight: 800;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
