.recipeQueue {
  display: flex;
  height: 100%;
}

.queue {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--black-light);
  padding: 1rem 1rem 0 1rem;
}

.recipes {
  flex: 1;
}

.buttons {
  margin-top: 3rem;
  text-align: center;
  width: 160px;
}

.buttons a {
  color: var(--primary-normal);
}

.buttons a:hover {
  color: var(--accent-normal);
}

.trend {
  background: greenyellow;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;

  margin: 0 -16px -16px -16px;
  font-size: 20px;
  font-weight: 800;
  z-index: 99999;
}

.trendHeader {
  font-size: 20px;
  font-weight: 800;
  background: var(--black-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.trendContent {
  width: 100%;
  height: 100%;
  display: none;
  background: var(--black-dark);
  padding-top: 16px;
}

.visible {
  display: block;
}

.noRecipe {
  font-size: 16px;
  padding-top: 32px;
}

.switchLabel:hover {
  cursor: pointer;
}

.recipeQueue :global .ant-switch-checked {
  background-color: var(--accent-normal);
}
