.app {
  margin-left: auto;
  margin-right: auto;
  width: 1900px;
  height: 100%;
}

.loadingComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  font-size: 20px;
  font-weight: 800;
}
