.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.selectedAndState {
  display: flex;
}

.commentIcon:hover {
  color: var(--accent-normal);
}

.table :global .anticon-arrow-right {
  color: var(--notification-green);
  font-size: 16px;
}

.table :global .anticon-arrow-left {
  color: var(--primary-normal);
  font-size: 16px;
}

.table :global .ant-table-container table > thead > tr:first-child th {
  font-weight: 800;
}

.table :global .ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 42px;
}

.title :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--accent-normal);
  border-color: var(--accent-normal);
}

.title :global .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--black-normal);
}
