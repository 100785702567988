.stockpileSelector {
  width: 320px;
  height: 500px;
  padding: 16px;
}

.stockpileList {
  padding-top: 16px;
}

.stockpile {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  background-color: var(--black-normal);
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 800;
  padding: 0 22px 0 0;
  color: white;
}

.stockpileSelected .stockpile {
  background-color: var(--black-light);
}

.state {
  font-size: 12px;
  border: 1px solid var(--black-light);
  padding: 2px 8px;
  border-radius: 4px;
}

.stockpileSelected .state {
  border-color: grey;
}

.selected {
  background-color: var(--black-normal);
  border-radius: 4px;
  width: 6px;
  height: 40px;
  margin-right: 18px;
}

.stockpileSelected .selected {
  background-color: var(--accent-normal);
}

.title {
  flex-grow: 1;
}
