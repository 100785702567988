.stockpile {
  composes: stockpile from '../../styles/StockpileSelector.module.css';
  padding-left: 16px;
  cursor: pointer;
}

.stockpileTitle {
  flex: 1;
}

.stockpileState {
  composes: state from '../../styles/StockpileSelector.module.css';
}

.stockpile :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--accent-normal);
  border-color: var(--accent-normal);
}

.stockpile :global .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--black-normal);
}
