.recipeQueue {
  padding-top: 32px;
  min-width: 440px;
}

.listOfRecipes {
  padding: 0 16px;
}

.recipeQueueTitle {
  color: var(--gray-normal);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
  padding-bottom: 16px;
}

.trend {
  width: 100%;
  height: 352px;
}

.loading {
  padding: 16px;
  color: var(--gray-normal);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
}

.show {
  display: block;
}

.hide {
  display: none;
}
