.recipeTrend {
  width: 100%;
  height: 350px;
  font-size: 14px;
  background: #1f1f1f;
  z-index: 1000;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.customLegend {
  display: flex;
  justify-content: center;
}

.legendItem {
  padding: 0 8px;
}

.legendItem:nth-last-child(2) {
  margin-left: 64px;
}
