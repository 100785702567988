.stockpileDetails {
  display: flex;
  min-height: 100%;
}

.details {
  border-left: 1px solid var(--black-light);
  padding: 16px;
  width: 1600px;
}

.pageHeader {
  display: flex;
  align-items: center;
  font-weight: 800;
  padding-bottom: 16px;
}

.pageHeader > span {
  color: var(--primary-normal);
  padding-right: 8px;
  padding-bottom: 16px;
}

.recalculating {
  padding: 32px 16px;
  font-size: 16px;
}
