.totals {
  font-weight: 800;
  font-size: 16px;
  background: #141414;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.totals :global .ant-row > .ant-col:not(:last-child):not(:first-child) {
  border-right: 1px solid #535353;
}

.totalsHeading {
  color: var(--accent-normal);
}

.totalsName {
  padding-right: 16px;
}

.totalsTotal {
  color: var(--accent-normal);
  padding-right: 16px;
}
