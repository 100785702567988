.layout :global .ant-layout-header {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 67px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.layout {
  height: 100%;
}

.content {
  height: 100%;
}

.noUserRoles {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  font-size: 16px;
  font-weight: 800;
}
