.commentModal > label {
  display: block;
  padding-bottom: 8px;
}

.commentModal > label::before {
  display: inline-block;
  margin-right: 4px;
  color: var(--error);
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
