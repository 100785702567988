.recipeQueueItem {
  font-size: 16px;
  padding: 16px;
  background: var(--black-normal);
  width: 100%;
  margin-bottom: 16px;
}
.listOfAreas {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.listOfAreas > li {
  display: flex;
  justify-content: space-between;
}

.recipeQueueItem :global .ant-divider-horizontal {
  margin: 16px 0;
}

.recipeQueueItem :global .ant-badge-status-dot {
  width: 12px;
  height: 12px;
}

.element {
  font-size: 14px;
  font-weight: 800;
  color: var(--accent-normal);
}

.grade {
  font-weight: 800;
}
