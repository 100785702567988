.sliceSelector {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  flex: 1;
  display: flex;
}

.selector {
  font-weight: 800;
  height: 100%;
  padding: 16px;
  font-size: 16px;
  color: gray;
  width: 320px;
  border-right: 1px solid var(--black-light);
}

.selectorTitle {
  margin-bottom: 1rem;
}

.selectorTitleExpandable {
  display: block !important;
  color: var(--accent-normal) !important;
  font-weight: bold !important;
  margin-bottom: 0.5rem;
  padding-left: 0 !important;
}

.selectorView {
  composes: stockpile from '../../styles/StockpileSelector.module.css';
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  cursor: pointer;
}

.selectorView > span {
  padding-right: 16px;
}

.highlightBlock {
  composes: selected from '../../styles/StockpileSelector.module.css';
}

.selectorViewSelected {
  color: var(--black-normal);
  background: var(--accent-normal);
}

.details {
  margin: 16px;
  flex: 1;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  background-color: var(--black-normal);
  height: 80px;
  margin-left: -16px;
  margin-right: -16px;
  padding-right: 16px;
}

.multiView {
  flex: 1;
}

.footer button {
  width: 180px;
}

.footer :global .ant-btn-primary {
  background: var(--primary-normal);
  border-color: var(--primary-normal);
}

.footer :global .ant-btn-primary:focus {
  background: var(--primary-dark);
  border-color: var(--primary-dark);
}

.footer :global .ant-btn-primary:hover {
  background: var(--primary-dark);
  border-color: var(--primary-dark);
}

.recalculating {
  font-size: 16px;
  padding-top: 32px;
}
