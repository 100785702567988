.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  /* display: flex; */
  /* align-items: center;
  justify-content: space-between; */
  font-weight: 800;
  font-size: 20px;
}

.addSliceButtonText {
  color: #ffffff;
  font-weight: 800
}

.addButton > span {
  color: var(--primary-normal);
}

.simpleStockpile :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--accent-normal);
  border-color: var(--accent-normal);
}

.simpleStockpile :global .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--black-normal);
}

.recalculating {
  font-size: 16px;
  padding: 32px 16px;
}

.recalculating:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
